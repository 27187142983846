import _ from 'lodash';
import { Moment } from 'moment';
import React from 'react';
import { Table } from 'buildingBlocks';
import { STRATEGY_TYPE, ALGORITHM_TYPE } from 'constantsBase';
import QueryStringLink from 'components/QueryStringLink';
import { generateUUID } from 'utils/formattingUtils';
import HealthTableHeaders from './HealthTableHeaders';
import { STRATEGY_FLIGHT_RUN_STATUSES } from '../constants';
import { StrategyFlightRun } from '../types';
import { getIsoDateTime } from '../utils';

const HEADERS = [
  'Strategy Type',
  'Algorithm Type',
  'Success',
  'Failed',
  'Running',
  'No Data',
  'Flight External Not Found',
  'Abort',
  'Cancelled',
  'Pending',
];

type Props = {
  strategyFlightRuns: Array<StrategyFlightRun>,
  startDate: Moment,
  endDate: Moment,
};

const SFRHealthTable = ({ strategyFlightRuns, startDate, endDate }: Props) => (
  <Table style={{ marginTop: 0 }}>
    <HealthTableHeaders headers={HEADERS} />
    <Table.Body>
      {
        _.map(strategyFlightRuns, (sfr) => (
          <Table.Row key={generateUUID()}>
            <Table.Cell>
              <QueryStringLink
                to="/strategyflightruns"
                params={{
                  strategyType: [sfr.strategyType],
                  startDate: getIsoDateTime(startDate),
                  endDate: getIsoDateTime(endDate),
                }}
              >
                {STRATEGY_TYPE.getById(sfr.strategyType as number)?.displayName}
              </QueryStringLink>
            </Table.Cell>
            <Table.Cell>
              <QueryStringLink
                to="/strategyflightruns"
                params={{
                  strategyType: [sfr.strategyType],
                  algorithmType: [sfr.algorithmType],
                  startDate: getIsoDateTime(startDate),
                  endDate: getIsoDateTime(endDate),
                }}
              >
                {sfr.algorithmType && ALGORITHM_TYPE.getById(sfr.algorithmType as number).name}
              </QueryStringLink>
            </Table.Cell>
            {
              _.map(STRATEGY_FLIGHT_RUN_STATUSES, (key) => (
                <Table.Cell key={generateUUID()}>
                  {
                    _.get(sfr.status, key)
                      ? (
                        <QueryStringLink
                          to="/strategyflightruns"
                          params={{
                            strategyType: [sfr.strategyType],
                            algorithmType: [sfr.algorithmType],
                            startDate: getIsoDateTime(startDate),
                            endDate: getIsoDateTime(endDate),
                            status: [key],
                          }}
                        >
                          {_.get(sfr.status, key)}
                        </QueryStringLink>
                      )
                      : '-'
                  }
                </Table.Cell>
              ))
            }
          </Table.Row>
        ))
      }
    </Table.Body>
  </Table>
);

export default SFRHealthTable;
