import React, { Dispatch, SetStateAction } from 'react';
import { Table, Icon, Button } from 'buildingBlocks';
import { COPILOT_LAYOUT, COPILOT_COLORS } from 'globalStyles';
import { DateSortOrder } from 'containers/NotificationCenter/types';
import { sortableHeaderStyles } from 'containers/NotificationCenter/style';

const { SPACING } = COPILOT_LAYOUT;
const { NEW_DESIGN_SYSTEM: { NEUTRALS } } = COPILOT_COLORS;

const { sortContainer, sortButton, sortIcon } = sortableHeaderStyles;

type SortableHeaderCellProps = {
  dateSortFilter: DateSortOrder
  handleSort: Dispatch<SetStateAction<DateSortOrder>>
};

const SortableHeaderCell = ({ dateSortFilter, handleSort }: SortableHeaderCellProps) => (
  <Table.HeaderCell
    style={sortContainer}
    onClick={handleSort}
  >
    Notification Date
    <Button style={sortButton}>
      <Icon
        name="caret up"
        style={{
          ...sortIcon,
          bottom: SPACING[12],
          color: (dateSortFilter === DateSortOrder.asc) ? NEUTRALS.N800_MATTER : NEUTRALS.N300_ALTO,
        }}
      />
      <Icon
        name="caret down"
        style={{
          ...sortIcon,
          bottom: SPACING[4],
          color: (dateSortFilter === DateSortOrder.desc) ? NEUTRALS.N800_MATTER : NEUTRALS.N300_ALTO,
        }}
      />
    </Button>
  </Table.HeaderCell>
);

export default SortableHeaderCell;
