import React, { Dispatch } from 'react';
import { Table } from 'buildingBlocks';
import { COPILOT_TYPOGRAPHY } from 'globalStyles';
import { DateSortOrder } from 'containers/NotificationCenter/types';
import SortableHeaderCell from '../Filters/SortableHeaderCell';

const { HEADING } = COPILOT_TYPOGRAPHY;

type NormalHeaderCellProps = {
  title: string
  width: number
};

const NormalHeaderCell = ({ title, width }: NormalHeaderCellProps) => (
  <Table.HeaderCell style={{ ...HEADING.H6, width: `${width}%` }}>
    {title}
  </Table.HeaderCell>
);

type NotificationHeaderProps = {
  dateSortFilter: DateSortOrder
  handleSort: Dispatch<DateSortOrder>
  headerTitle: string
};

const NotificationHeader = ({ dateSortFilter, handleSort, headerTitle }: NotificationHeaderProps) => (
  <Table.Header>
    <Table.Row>
      <SortableHeaderCell
        dateSortFilter={dateSortFilter}
        handleSort={handleSort}
      />
      <NormalHeaderCell title="Subject" width={14} />
      <NormalHeaderCell title="Message" width={29} />
      <NormalHeaderCell title={headerTitle} width={29} />
      <NormalHeaderCell title="Actions" width={13} />
    </Table.Row>
  </Table.Header>
);

export default NotificationHeader;
