import React, { Dispatch } from 'react';
import { Table } from 'buildingBlocks';
import { SelectedCategoryFilter } from 'components/Notification/constants';
import { tableStyles } from 'containers/NotificationCenter/style';
import { NotificationMsgObject } from 'components/Notification/types';
import { DateSortOrder, State as FilterState } from 'containers/NotificationCenter/types';
import NotificationTableBody from './NotificationTableBody';
import NotificationHeader from './NotificationHeader';

const { tableCustomStyle } = tableStyles;

const STRATEGY_INFO = 'Strategy Info';
const MORE_DETAILS = 'More Details';

type NotificationTableProps = {
  notifications: Array<NotificationMsgObject>
  dateSortFilter: DateSortOrder
  handleSort: Dispatch<DateSortOrder>
  selectedCategoryFilter: SelectedCategoryFilter
  filterState: FilterState
};

const NotificationTable = ({
  notifications,
  dateSortFilter,
  handleSort,
  selectedCategoryFilter,
  filterState,
}: NotificationTableProps) => {
  const headerTitle = selectedCategoryFilter === SelectedCategoryFilter.actions ? STRATEGY_INFO : MORE_DETAILS;
  return (
    <Table style={tableCustomStyle}>
      <NotificationHeader
        dateSortFilter={dateSortFilter}
        handleSort={handleSort}
        headerTitle={headerTitle}
      />
      <NotificationTableBody
        notifications={notifications}
        filterState={filterState}
      />
    </Table>
  );
};

export default NotificationTable;
