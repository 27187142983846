/* eslint-disable react/no-unused-prop-types */
import _ from 'lodash';
import { Routes, Route, Outlet } from 'react-router';
import React, { Component } from 'react';
import { Moment } from 'moment';
import { connect } from 'react-redux';
import { Menu, Segment, Tab } from 'buildingBlocks';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import { TabItem } from 'components/Tabs';
import { Permission } from 'utils/featureFlags';
import DateRangePicker from 'components/DateRangePicker';
import JobsHealthTable from './components/JobsHealthTable';
import SFRHealthTable from './components/SFRHealthTable';
import DagRunsHealthTable from './components/DagRunsHealthTable';
import { getSummaryData } from './actions';
import { Job, StrategyFlightRun, DagRun } from './types';

type Props = {
  getSummaryData: Function,
  jobs?: Array<Job>,
  strategyFlightRuns?: Array<StrategyFlightRun>,
  dagRuns?: Array<DagRun>,
  startDate: Moment,
  endDate: Moment,
};

export type TabType = {
  label: string,
  to: string,
  props: any,
};

type TabProps = {
  tabs: { [key: string]: TabType },
};

const TabView = ({ tabs }: TabProps) => (
  <>
    {
      tabs
      && (
        <Menu className="custom-tab" style={{ marginBottom: 0 }} tabular>
          {
            _.map(tabs, (tab) => (
              <TabItem key={tab.label} to={tab.to}>{tab.label}</TabItem>
            ))
          }
        </Menu>
      )
    }
    <Routes>
      <Route path="/jobs" element={(<Tab.Pane><JobsHealthTable {...tabs.jobs.props} /></Tab.Pane>)} />
      <Route path="/sfr" element={(<Tab.Pane><SFRHealthTable {...tabs.sfr.props} /></Tab.Pane>)} />
      <Route path="/dags" element={(<Tab.Pane><DagRunsHealthTable {...tabs.dags.props} /></Tab.Pane>)} />
    </Routes>
  </>
);

const getTabs = (props: Props) => ({
  jobs: {
    label: 'Jobs',
    to: '/health/jobs',
    props: {
      jobs: props.jobs,
      startDate: props.startDate,
      endDate: props.endDate,
    },
  },
  sfr: {
    label: 'Strategy Flight Runs',
    to: '/health/sfr',
    props: {
      strategyFlightRuns: props.strategyFlightRuns,
      startDate: props.startDate,
      endDate: props.endDate,
    },
  },
  dags: {
    label: 'DAGs',
    to: '/health/dags',
    props: {
      dagRuns: props.dagRuns,
      startDate: props.startDate,
      endDate: props.endDate,
    },
  },
});

export class CopilotHealth extends Component<Props> {
  UNSAFE_componentWillMount() {
    this.props.getSummaryData(this.props.startDate, this.props.endDate);
  }

  render() {
    return (
      <>
        <PermissionPageTemplate
          name="Copilot Health"
          permissions={[Permission.manageJobs]}
        >
          <Segment>
            <DateRangePicker
              onDateRangeSubmit={this.props.getSummaryData}
              hasApplyButton
              enableFutureDateSelection
            />
          </Segment>
          <TabView tabs={getTabs(this.props)} />
        </PermissionPageTemplate>
        <Outlet />
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    jobs, strategyFlightRuns, dagRuns, startDate, endDate,
  } = state.copilotHealth;
  return {
    jobs,
    strategyFlightRuns,
    dagRuns,
    startDate,
    endDate,
  };
}

export default connect(mapStateToProps, { getSummaryData })(CopilotHealth);
