import moment from 'moment';
import React, { Dispatch, forwardRef, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Button } from 'buildingBlocks';
import { datePickerStyles } from 'containers/NotificationCenter/style';
import { updateDateRange } from 'containers/NotificationCenter/actions';
import { Action } from 'containers/NotificationCenter/types';

const { calendarIconStyle, colorSetting, customInputContainer, calendarWrapper } = datePickerStyles;

type CalendarProps = {
  onClick?: () => void
  onChange?: (e) => void
  value?: string
  placeholder?: string
  disabled?: boolean
};

const CustomInput = forwardRef<any, CalendarProps>((props, ref) => {
  const { onClick, onChange, disabled, value, placeholder, ...otherProps } = props;
  return (
    <div style={{ ...customInputContainer, ...colorSetting }}>
      <input
        type="text"
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        placeholder={placeholder}
        value={value}
        style={{ backgroundColor: 'transparent', border: 0, width: '156px' }}
        disabled={disabled}
        {...otherProps}
      />
      <Button
        style={{ ...calendarIconStyle, ...colorSetting, ...(disabled && { cursor: 'default' }) }}
        icon="calendar alternate outline"
        onClick={onClick}
      />
    </div>
  );
});

type DatePickerProps = {
  dateRange: string
  dispatch: Dispatch<Action>
  isLoading: boolean
};

const DatePicker = ({
  dateRange,
  dispatch,
  isLoading,
}: DatePickerProps) => {
  const [reducerStartDate, reducerEndDate] = dateRange.split(' - ');
  const [startDate, setStartDate] = useState<Date>(moment(reducerStartDate).toDate());
  const [endDate, setEndDate] = useState<Date>(moment(reducerEndDate).toDate());

  useEffect(() => {
    setStartDate(moment(reducerStartDate).toDate());
    setEndDate(moment(reducerEndDate).toDate());
  }, [reducerStartDate, reducerEndDate]);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      dispatch(updateDateRange(start, end));
    }
  };

  const handleCalendarClose = () => {
    if (!endDate) {
      setEndDate(moment().toDate());
    }
  };
  // TODO: custom input range is not saving. Seems to be issue with react-datepicker
  /* Issue on react-datepicker's end. Check site: https://reactdatepicker.com/#example-date-range-for-one-datepicker
  *  It is automatically hightlighting the startDate's date for next month
  */
  return (
    <div style={calendarWrapper}>
      <ReactDatePicker
        placeholderText="All Dates"
        onChange={handleDateChange}
        onCalendarClose={handleCalendarClose}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        minDate={moment().subtract(30, 'd').toDate()}
        maxDate={moment().toDate()}
        selectsRange
        customInput={<CustomInput />}
        disabled={isLoading}
      />
    </div>
  );
};

export default DatePicker;
