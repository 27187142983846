/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Table, Icon, Popup, Image } from 'buildingBlocks';
import { actionsStyles } from 'containers/NotificationCenter/style';

const {
  tableCell, actionsContainer, anchorText,
  diagonalArrowIcon, popUp, popUpContent, popUpImage,
  takeAction, takeActionText, ellipsis,
} = actionsStyles;

const NOTIFICATION_ICON = 'img/icons/notifications/notification.svg';
const NOTIFICATION_READ_ICON = 'img/icons/notifications/notificationRead.svg';

type NotificationEllipsisProps = {
  hasActionsLink: boolean
  actionLinkDisplayValue: string
  read: boolean
  openPopUp: boolean
  togglePopUp: () => void
  handleTakeAction: () => void
  handleMarkAs: () => void
  displayEllipsis: boolean
};

const NotificationActions = ({
  hasActionsLink,
  actionLinkDisplayValue,
  read,
  openPopUp,
  togglePopUp,
  handleTakeAction,
  handleMarkAs,
  displayEllipsis,
}: NotificationEllipsisProps) => (
  <Table.Cell style={tableCell}>
    <div style={actionsContainer}>
      <div
        className="notif-center-action-link"
        style={{ ...takeAction, ...(hasActionsLink && anchorText) }}
        onClick={handleTakeAction}
      >
        {hasActionsLink && (
          <>
            <p style={takeActionText}>{actionLinkDisplayValue}</p>
            <Icon name="arrow up" style={diagonalArrowIcon} />
          </>
        )}
      </div>
      {displayEllipsis && (
        <Popup
          on="click"
          basic
          open={openPopUp}
          onOpen={togglePopUp}
          onClose={togglePopUp}
          trigger={<Icon name="ellipsis vertical" style={ellipsis} />}
          style={popUp}
        >
          <Popup.Content style={popUpContent}>
            <div style={popUpImage} onClick={handleMarkAs}>
              <Image src={read ? NOTIFICATION_READ_ICON : NOTIFICATION_ICON} />Mark as {read ? 'Unread' : 'Read'}
            </div>
          </Popup.Content>
        </Popup>
      )}
    </div>
  </Table.Cell>
);

export default NotificationActions;
