import _ from 'lodash';
import React, { CSSProperties, Dispatch } from 'react';
import { Button } from 'buildingBlocks';

type NotificationsFilterButtonProps = {
  filterName: string
  selectedFilter: string
  setSelectedFilter: Dispatch<string>
  isLoading: boolean
  baseStyle: CSSProperties
  activeStyle: CSSProperties
};

const NotificationsFilterButton = ({ filterName, selectedFilter, setSelectedFilter, isLoading, baseStyle, activeStyle }: NotificationsFilterButtonProps) => {
  const isSelected = selectedFilter === filterName;
  return (
    <Button
      style={{ ...baseStyle, ...(isSelected && { ...activeStyle }) }}
      onClick={() => setSelectedFilter(filterName)}
      disabled={!isSelected && isLoading}
    >
      {_.capitalize(filterName)}
    </Button>
  );
};

export default NotificationsFilterButton;
