import _ from 'lodash';
import React, { Component, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import { Permission } from 'utils/featureFlags';
import { Segment, Header, Button, Dropdown } from 'buildingBlocks';
import { getAuthHeaders } from './actions';
import { Headers, LoadingState } from './types';
import { LOADING_STATES, EXPIRE_TEXT, FAILURE_TEXT } from './constants';

type Props = {
  headers: Headers,
  getAuthHeaders: typeof getAuthHeaders,
  loadingState: LoadingState,
};

type StoreState = {
  authToken: {
    headers: Headers,
    loadingState: LoadingState,
  },
};

const formatOptions = [
  {
    key: 'keyvalue',
    text: 'Key / Value',
    value: 'keyvalue',
  },
  {
    key: 'JSON',
    text: 'JSON',
    value: 'json',
  },
];

type AuthDisplayFormatComponentProps = {
  headers: Headers,
};

export const AuthDisplayFormatComponent = ({ headers } : AuthDisplayFormatComponentProps) => {
  const [selectedFormat, setFormat] = useState('keyvalue');
  const keyValueToken = _.map(headers, (value, key) => `${key}: ${value}`);
  const jsonToken = JSON.stringify(headers, null, 2);
  const authTokenFormattedValue = _.isEqual(selectedFormat, 'keyvalue') ? keyValueToken.join('\n') : jsonToken;
  return (
    <>
      <Header as="h4" key="0">{EXPIRE_TEXT}</Header>
      <Dropdown
        placeholder="Select Format"
        selection
        options={formatOptions}
        defaultValue={selectedFormat}
        onChange={(_e, data: { value: string }) => setFormat(data.value)}
      />
      <pre>{authTokenFormattedValue}</pre>
      <CopyToClipboard text={authTokenFormattedValue}><Button primary>Copy to clipboard</Button></CopyToClipboard>
    </>
  );
};

export class AuthToken extends Component<Props> {
  componentDidMount() {
    this.getAuthHeaders();
  }

  getAuthHeaders = () => this.props.getAuthHeaders();

  render() {
    const { loadingState, headers } = this.props;
    return (
      <PermissionPageTemplate
        name="Generate API Auth Token"
        permissions={[Permission.manageJobs]}
      >
        <Segment loading={loadingState === LOADING_STATES.loading}>
          {
            loadingState !== LOADING_STATES.error
              ? <AuthDisplayFormatComponent headers={headers} /> : <Header as="h4">{FAILURE_TEXT}</Header>
          }
          <Button primary onClick={this.getAuthHeaders}>Regenerate</Button>
        </Segment>
      </PermissionPageTemplate>
    );
  }
}

const mapStateToProps = (state: StoreState) => ({
  headers: state.authToken.headers,
  loadingState: state.authToken.loadingState,
});

export default connect(mapStateToProps, { getAuthHeaders })(AuthToken);
