import _ from 'lodash';
import React, { Dispatch, useEffect } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';
import qs from 'qs';
import PageTemplate from 'components/PageTemplate';
import PaginationControl from 'components/PaginationControl';
import { NotificationMsgObject } from 'components/Notification/types';
import { Action, DateSortOrder, State as FilterState, NotificationStates } from 'containers/NotificationCenter/types';
import { resetPagination, updatePagination, updateSortOrder } from 'containers/NotificationCenter/actions';
import { noNotificationsStyle } from 'containers/NotificationCenter/style';
import { ISO_DATE } from 'utils/dateTime';
import { usePrevious } from 'utils/hooks/usePrevious';
import { Brand } from 'utils/types';
import { PAGE_NAME } from 'containers/NotificationCenter/NotificationCenter';
import PageLoading from 'components/PageLoading';
import NotificationsFilters from '../Filters/NotificationsFilters';
import NotificationTable from './NotificationTable';

const NO_RESULT_MSG = 'There are no notifications.';
const ELEMENTS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
const LOADING_MSG = 'Loading Notifications';

type NotificationsPageProps = {
  pageName: string
  filterState: FilterState
  dispatch: Dispatch<Action>
  notifications: Array<NotificationMsgObject>
  brandCandidates: Array<Brand>
  loadingBrands: boolean
  brandError: string
  notificationState: string
};

const NotificationsPage = ({
  pageName,
  filterState,
  dispatch,
  notifications,
  brandCandidates,
  loadingBrands,
  brandError,
  notificationState,
}: NotificationsPageProps) => {
  const navigate = useNavigate();
  const prevFilter = usePrevious(filterState);

  const {
    selectedCategoryFilter,
    dateRange,
    selectedBrands,
    unreadOnly,
    viewFilter,
    elementsPerPage,
    pageNumber,
    numberOfElements,
    skip,
    sortOrder,
  } = filterState;

  const updateBrowserUrl = () => {
    const [startDate, endDate] = dateRange.split(' - ');
    const brandIds = _.map(selectedBrands, 'id');
    const startDateISO = moment(startDate).format(ISO_DATE);
    const endDateISO = moment(endDate).format(ISO_DATE);

    const filter = {
      ..._.omit(filterState, ['selectedBrands', 'dateRange']),
      brands: brandIds,
      startDate: startDateISO,
      endDate: endDateISO,
    };

    const qsParam = qs.stringify(filter);
    navigate({ search: qsParam });
  };

  const getOnlyFilters = (filterObj) => _.omit(filterObj, ['elementsPerPage', 'pageNumber', 'skip']);

  useEffect(() => {
    if (!_.isEmpty(prevFilter) && !_.isEmpty(filterState) && !_.isEqual(filterState, prevFilter)) {
      const prev = getOnlyFilters(prevFilter);
      const cur = getOnlyFilters(filterState);
      // reset pagination if filters are changed
      if (!_.isEqual(prev, cur)) {
        dispatch(resetPagination());
      }
      updateBrowserUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryFilter, dateRange, selectedBrands, unreadOnly, viewFilter, elementsPerPage, pageNumber, skip, sortOrder]);

  const handleSort = () => {
    const newSortOrder = sortOrder === DateSortOrder.desc ? DateSortOrder.asc : DateSortOrder.desc;
    dispatch(updateSortOrder(newSortOrder));
  };

  const handlePagination = ({ limit, start }: { limit: number, start: number }) => {
    dispatch(updatePagination(limit, start));
  };

  const isLoading = notificationState === NotificationStates.loading;

  return (
    <PageTemplate name={pageName}>
      <NotificationsFilters
        dateRange={dateRange}
        isLoading={isLoading}
        dispatch={dispatch}
        filterState={filterState}
        brandCandidates={brandCandidates}
        loadingBrands={loadingBrands}
        brandError={brandError}
      />
      {(notificationState === NotificationStates.initial || isLoading)
        && <PageLoading pageName={PAGE_NAME} showDimmer={false}>{LOADING_MSG}</PageLoading>}
      {_.size(notifications) && !isLoading
        ? (
          <>
            <NotificationTable
              notifications={notifications}
              dateSortFilter={sortOrder}
              handleSort={handleSort}
              selectedCategoryFilter={selectedCategoryFilter}
              filterState={filterState}
            />
            <PaginationControl
              numberOfElements={numberOfElements}
              onDataRangeChange={handlePagination}
              startingElementsPerPage={elementsPerPage}
              elementsPerPageOptions={ELEMENTS_PER_PAGE_OPTIONS}
              startingPage={pageNumber}
            />
          </>
        )
        : <div style={noNotificationsStyle}>{!isLoading && NO_RESULT_MSG}</div>}
    </PageTemplate>
  );
};

export default NotificationsPage;
