export const mainContainer = {
  padding: '20px',
  color: '#607d8b',
};

export const memberLabel = {
  marginRight: '5%',
  fontWeight: '400',
};

export const tableHeader = {
  backgroundColor: '#fff',
};

export const noBorderRadius = {
  borderRadius: '0',
};

export const filterSegment = {
  boxShadow: 'none',
  borderRadius: '0',
};

export const jobsTable = {
  marginTop: '1%',
  borderRadius: '0',
};

export const timeZoneDropDown = {
  width: '171px',
  display: 'inline-block',
};
