import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table } from 'buildingBlocks';
import { SelectedCategoryFilter } from 'components/Notification/constants';
import { NotificationMsgObject } from 'components/Notification/types';
import { getMessageAction, readNotification } from 'components/Notification/utils';
import { InfoToDisplay, State as FilterState } from 'containers/NotificationCenter/types';
import { COPILOT_COLORS } from 'globalStyles';
import { NUMBER_DATE_FORMAT } from 'utils/dateTime';
import NotificationActions from './NotificationActions';
import NotificationSubject from './NotificationSubject';
import NotificationMessage from './NotificationMessage';
import NotificationInfo from './NotificationInfo';

const { NEW_DESIGN_SYSTEM: { METTLES, NEUTRALS } } = COPILOT_COLORS;

type NotificationRowProps = {
  notification: NotificationMsgObject
  filterState: FilterState
  userId: number
};

const NotificationRow = ({ notification, filterState, userId }: NotificationRowProps) => {
  const { readAt, metadata, strategyName, createdAt } = notification;
  const isUsersNotification = _.isEqual(notification.user, userId);

  const [openPopUp, setOpenPopup] = useState<boolean>(false);
  const [read, setRead] = useState<boolean>(isUsersNotification ? !!readAt : false);
  const [hovered, setHovered] = useState<boolean>(false);
  const dispatch = useDispatch();

  const metaDataObj = JSON.parse(metadata);
  const action = getMessageAction(_.get(metaDataObj, 'actions', []));
  const actionLink = action?.link;
  // actions.displayValue may not exist until model update - default will be 'Take Action'
  const actionLinkDisplayValue = _.get(action, 'displayValue', 'Take Action');

  const displayStratInfo = filterState.selectedCategoryFilter === SelectedCategoryFilter.actions && !!strategyName;
  const moreDetailsInfoToDisplay = _.head(metaDataObj?.summary) as object ?? {};
  const infoToDisplay = displayStratInfo
    ? _.pick(notification, ['createdByName', 'strategyName', 'member', 'advertiser', 'strategyId', 'strategyActive'])
    : { ...moreDetailsInfoToDisplay, strategyActive: notification.strategyActive };

  // syncs read when user read/unread notification and switches My/All filter
  useEffect(() => {
    if (isUsersNotification) {
      setRead(!!readAt);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const togglePopUp = () => setOpenPopup(!openPopUp);

  const updateNotificationReadStatus = async (uId: number, readBool: boolean = true) => {
    if (isUsersNotification) {
      setRead(readBool);
      await readNotification(notification.id, uId, filterState.selectedCategoryFilter, readBool, dispatch);
    }
  };

  const handleTakeAction = async () => {
    await updateNotificationReadStatus(userId);
    window.open(actionLink, '_blank');
  };

  const toggleNotificationReadStatus = async () => {
    await updateNotificationReadStatus(userId, !read);
  };

  const baseColor = hovered ? NEUTRALS.N50_ALABASTER : NEUTRALS.N0_WHITE;
  const notificationDate = moment(createdAt).format(NUMBER_DATE_FORMAT);

  return (
    <Table.Row
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      style={{ backgroundColor: (read ? METTLES.M50_FLASH : baseColor) }}
    >
      <Table.Cell style={{ width: '15%' }}>
        {notificationDate}
      </Table.Cell>
      <NotificationSubject
        notification={notification}
        read={read}
      />
      <NotificationMessage notification={notification} />
      <NotificationInfo
        infoToDisplay={(infoToDisplay as InfoToDisplay)}
      />
      <NotificationActions
        hasActionsLink={!!actionLink}
        actionLinkDisplayValue={actionLinkDisplayValue}
        read={read}
        openPopUp={openPopUp}
        togglePopUp={togglePopUp}
        handleTakeAction={handleTakeAction}
        handleMarkAs={toggleNotificationReadStatus}
        displayEllipsis={isUsersNotification}
      />
    </Table.Row>
  );
};

export default NotificationRow;
