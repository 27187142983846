// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#okta-sign-in.auth-container.main-container {
  box-shadow: var(--card-primary-box-shadow);
  border-radius: 12px 16px 16px 16px;
}

#okta-sign-in .o-form label,
#okta-sign-in .o-form textarea {
  color: #4d5358;
  font-weight: 600 !important;
}

#okta-sign-in.auth-container .button-primary {
  box-shadow: 0px 0px 1px rgba(52, 58, 63, 0.1), 0px 1px 5px rgba(52, 58, 63, 0.1);
  border-radius: 8px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
}

#okta-sign-in.auth-container .okta-form-input-field {
  border-radius: var(--wpp-border-radius-m);
  outline: none;
  background-color: var(--text-input-bg-color);
}

#okta-sign-in .o-form .input-fix input:-webkit-autofill,
#okta-sign-in .o-form .textarea-fix input:-webkit-autofill {
  border-radius: 8px;
}

#okta-sign-in .focused-input,
#okta-sign-in .link.help:focus,
#okta-sign-in input[type=radio]:focus + label,
#okta-sign-in input[type=text]:focus {
  box-shadow: none;
}

#okta-sign-in.auth-container .okta-form-input-field.focused-input {
  border-color: #4d5358;
}`, "",{"version":3,"sources":["webpack://./src/containers/Login/style.scss"],"names":[],"mappings":"AAIA;EACE,0CAAA;EACA,kCAAA;AAHF;;AAOA;;EAEE,cAXM;EAYN,2BAAA;AAJF;;AAQA;EACE,gFAAA;EAEA,kBApBc;EAqBd,YAAA;EACA,gBAAA;EACA,eAAA;AANF;;AAUA;EACE,yCAAA;EACA,aAAA;EACA,4CAAA;AAPF;;AAUA;;EAEE,kBAnCc;AA4BhB;;AAUA;;;;EAIE,gBAAA;AAPF;;AAUA;EACE,qBA7CM;AAsCR","sourcesContent":["$border-radius: 8px;\n$color: #4d5358;\n\n// Login container\n#okta-sign-in.auth-container.main-container {\n  box-shadow: var(--card-primary-box-shadow);\n  border-radius: 12px 16px 16px 16px;\n}\n\n// Input field label\n#okta-sign-in .o-form label,\n#okta-sign-in .o-form textarea {\n  color: $color;\n  font-weight: 600 !important;\n}\n\n// Primary Button\n#okta-sign-in.auth-container .button-primary {\n  box-shadow: 0px 0px 1px rgba(52, 58, 63, 0.1),\n    0px 1px 5px rgba(52, 58, 63, 0.1);\n  border-radius: $border-radius;\n  height: 40px;\n  font-weight: 600;\n  font-size: 14px;\n}\n\n// Input field\n#okta-sign-in.auth-container .okta-form-input-field {\n  border-radius: var(--wpp-border-radius-m);\n  outline: none;\n  background-color: var(--text-input-bg-color);\n}\n\n#okta-sign-in .o-form .input-fix input:-webkit-autofill,\n#okta-sign-in .o-form .textarea-fix input:-webkit-autofill {\n  border-radius: $border-radius;\n}\n\n#okta-sign-in .focused-input,\n#okta-sign-in .link.help:focus,\n#okta-sign-in input[type=\"radio\"]:focus + label,\n#okta-sign-in input[type=\"text\"]:focus {\n  box-shadow: none;\n}\n\n#okta-sign-in.auth-container .okta-form-input-field.focused-input {\n  border-color: $color;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
