import React from 'react';
import _ from 'lodash';
import { Table } from 'buildingBlocks';

type Props = {
  headers: Array<string>,
};

const HealthTableHeaders = ({ headers }: Props) => (
  <Table.Header>
    <Table.Row>
      {_.map(headers, (header) => <Table.HeaderCell key={header}>{header}</Table.HeaderCell>)}
    </Table.Row>
  </Table.Header>
);

export default HealthTableHeaders;
