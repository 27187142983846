import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import parse from 'html-react-parser';
import { Table, Popup, Image } from 'buildingBlocks';
import { NotificationMsgObject } from 'components/Notification/types';
import { messageStyles } from 'containers/NotificationCenter/style';
import { NUMBER_DATE_FORMAT } from 'utils/dateTime';

const { dupMsg, tableCell, message, triangleIcon } = messageStyles;

const TRIANGLE_SVG = 'img/components/StrategyWizard/yellow-exclamation-triangle-outline.svg';
const TEN_LINES_LIMIT = 500;

type DuplicateMessageProps = {
  notification: NotificationMsgObject
};

const DuplicateMessage = ({ notification }: DuplicateMessageProps) => (
  <div style={dupMsg}>
    <Image src={TRIANGLE_SVG} style={triangleIcon} />
    This notification has repeated {notification.count} times since {moment(notification.repeatedSince).format(NUMBER_DATE_FORMAT)}
  </div>
);

type NotificationMessageProps = {
  notification: NotificationMsgObject
};

const NotificationMessage = ({ notification }: NotificationMessageProps) => {
  const { content, count } = notification;
  // some messages' content contains HTML
  // add target="_blank" to open up links in new tab
  const parsedContent = parse(_.replace(content, '<a', '<a target="_blank"'));
  return (
    <Table.Cell style={tableCell}>
      {_.size(content) > TEN_LINES_LIMIT
        ? (
          <Popup
            on="hover"
            position="right center"
            trigger={(<div style={message}>{parsedContent}</div>)}
            content={(<div>{parsedContent}</div>)}
          />
        )
        : (<div>{parsedContent}</div>)}
      {count > 1 && (<DuplicateMessage notification={notification} />)}
    </Table.Cell>
  );
};

export default NotificationMessage;
