import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'buildingBlocks';
import { stratInfoStyles } from 'containers/NotificationCenter/style';
import { StrategyInfo as StrategyInfoType, MoreDetailsInfo, InfoToDisplay } from 'containers/NotificationCenter/types';
import { truncate } from 'utils/formattingUtils';

const SINGLE_LINE_LIMIT = 30;
const THREE_LINE_LIMIT = 140;

const {
  container, colorMatter, memAdvSeparate,
  adv, mem, colorSherpa, memAdvOneLine,
} = stratInfoStyles;

type StrategyInfoProps = {
  infoToDisplay: StrategyInfoType
};

const StrategyInfo = ({ infoToDisplay }: StrategyInfoProps) => {
  const { strategyName, advertiser, member, createdByName, strategyId, strategyActive } = infoToDisplay;
  const strategyText = truncate(strategyName, THREE_LINE_LIMIT);
  const strategyLink = `/strategies/${strategyId}`;
  return (
    <div style={container}>
      <div style={colorMatter}>
        {strategyActive ? (
          <Link
            to={strategyLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {strategyText}
          </Link>
        ) : strategyText}
      </div>
      <div style={_.size(advertiser) > SINGLE_LINE_LIMIT ? memAdvSeparate : memAdvOneLine}>
        <span style={adv}>{advertiser}</span>
        <span style={mem}>{member}</span>
      </div>
      <div style={colorMatter}>
        <span style={colorSherpa}>Strategy Creator:</span> {createdByName}
      </div>
    </div>
  );
};

type MoreDetailsInfoProps = {
  infoToDisplay: MoreDetailsInfo
};

const MoreDetails = ({ infoToDisplay }: MoreDetailsInfoProps) => {
  const displayText = truncate(infoToDisplay?.displayValue ?? '', THREE_LINE_LIMIT);
  const isStrategyLink = _.isEqual(infoToDisplay?.title, 'Strategy');
  const showLink = infoToDisplay?.link && ((isStrategyLink && infoToDisplay.strategyActive) || !isStrategyLink);
  return (
    <div style={container}>
      <div style={colorMatter}>
        {showLink ? <a href={infoToDisplay.link} target="_blank" rel="noopener noreferrer">{displayText}</a> : displayText}
      </div>
    </div>
  );
};

type NotificationInfoProps = {
  infoToDisplay: InfoToDisplay
};

const NotificationInfo = ({ infoToDisplay }: NotificationInfoProps) => {
  const isStrategyInfo = (displayObj: InfoToDisplay): displayObj is StrategyInfoType => !!_.get(displayObj, 'strategyName');
  return (
    <Table.Cell style={{ width: '29%' }}>
      {isStrategyInfo(infoToDisplay)
        ? (<StrategyInfo infoToDisplay={infoToDisplay} />)
        : (<MoreDetails infoToDisplay={infoToDisplay} />)}
    </Table.Cell>
  );
};

export default NotificationInfo;
