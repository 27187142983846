import React from 'react';
import { Table } from 'buildingBlocks';
import { COPILOT_COLORS } from 'globalStyles';
import { NotificationMsgObject } from 'components/Notification/types';
import { subjectStyles } from 'containers/NotificationCenter/style';

const { subjectStyle, countStyle, subjectContainer } = subjectStyles;

const { NEW_DESIGN_SYSTEM: { NEUTRALS } } = COPILOT_COLORS;

type NotificationSubjectProps = {
  notification: NotificationMsgObject
  read: boolean
};

const NotificationSubject = ({ notification, read }: NotificationSubjectProps) => {
  const { subject, count } = notification;
  return (
    <Table.Cell style={{ width: '14%' }}>
      <div style={subjectContainer}>
        <div style={{ ...subjectStyle, color: (read ? NEUTRALS.N300_ALTO : NEUTRALS.N800_MATTER) }}>
          {subject}
        </div>
        {count > 1 && (
          <div style={countStyle}>
            {count}
          </div>
        )}
      </div>
    </Table.Cell>
  );
};

export default NotificationSubject;
