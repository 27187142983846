import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import {
  AgGrid,
  AgGridHeader,
  Message,
  Transition,
  WppGrid,
  WppPagination,
} from 'buildingBlocks';
import DataLoading from 'components/DataLoading';
import WppDeleteConfirmationModal from 'components/WppDeleteConfirmationModal';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import { hasBrandManagerRole, isAdmin } from 'containers/User/utils';
import PageForbidden from 'containers/403';
import { GlobalState } from 'reducers';
import { Brand as BrandAPI } from 'utils/copilotAPI';
import { Permission } from 'utils/featureFlags';
import { isQAGlobalReadOnly } from 'utils/functionHelpers';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { Brand, PaginationChangeEventDetail, User } from 'utils/types';
import {
  BRANDS_PER_PAGE_OPTIONS,
  BRANDS_STARTING_FETCH_LIMIT,
  INITIAL_BRANDS_TO_DELETE_FORM_VALUES,
} from './constants';
import { browserUrlUpdate, getBrands, brandPluralizer } from './utils';
import { AdminBrandsToDeleteForm } from './types';
import BrandsPageHeader from './components/BrandsPageHeader';
import BrandsTableHeader from './components/BrandsTableHeader';

const PAGE_NAME = 'Brands';

type StatusMessageType = {
  header: string
  body: string
  isFailure: boolean
};

const Brands: React.FC = (): React.ReactElement => {
  const gridRef = useRef<AgGridReact<Brand>>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const isAdminUser = isAdmin(user);
  const isQAGlobal = isQAGlobalReadOnly(user);
  const isAdminOrQAGlobal = isAdminUser || isQAGlobal;
  const isBrandManager = hasBrandManagerRole(user);
  const canEditBrand = !(!isAdminOrQAGlobal && !isBrandManager);

  const [fetchLimit, setFetchLimit] = useState<number>(
    BRANDS_STARTING_FETCH_LIMIT,
  );
  const [searchStr, setSearchStr] = useState<string>('');
  const [startingPage, setStartingPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [brands, setBrands] = useState<Array<Brand>>([]);
  const [brandsCount, setBrandsCount] = useState<number>(0);
  const [statusMessage, setStatusMessage] = useState<StatusMessageType>(
    location.state ? location.state.statusMessage : '',
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const formMethods = useForm<AdminBrandsToDeleteForm>({
    defaultValues: INITIAL_BRANDS_TO_DELETE_FORM_VALUES,
  });
  const { control, reset, setValue } = formMethods;
  const brandsToDelete = useWatch({ name: 'brandsToDelete', control });
  const showDeletePanel = !_.isEmpty(brandsToDelete) && isAdminOrQAGlobal;

  const updatePaginationAndGetMessages = async (limit: number, skip: number, search: string) => {
    setFetchLimit(limit);
    setSearchStr(search);
    setStartingPage(skip / limit + 1);
    await getBrands(limit, skip, search, setBrandsCount, setBrands);
    setLoading(false);
  };

  useMount(() => {
    const { limit, skip, search } = qs.parse(
      location.search.replace(/^\?/, ''),
    );
    const parsedLimit = (_.toNumber(limit) > 0 && parseInt(limit as string, 10))
      || BRANDS_STARTING_FETCH_LIMIT;
    const parsedSkip = (_.toNumber(skip) >= 0 && parseInt(skip as string, 10)) || 0;
    const parsedSearch = _.isUndefined(search) ? '' : search;
    updatePaginationAndGetMessages(
      parsedLimit,
      parsedSkip,
      parsedSearch as string,
    );
  });

  useEffect(() => {
    let timeout = null;
    if (statusMessage) {
      timeout = setTimeout(() => {
        clearTimeout(timeout);
        setStatusMessage(null);
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [statusMessage]);

  const onDataRangeChange = ({
    limit,
    start,
  }: {
    limit: number;
    start: number;
  }) => {
    gridRef.current!.api.deselectAll();
    reset(INITIAL_BRANDS_TO_DELETE_FORM_VALUES);
    browserUrlUpdate(limit, start, searchStr, location, navigate);
    updatePaginationAndGetMessages(limit, start, searchStr);
  };

  const onSearchChange = (search: string) => {
    reset(INITIAL_BRANDS_TO_DELETE_FORM_VALUES);
    browserUrlUpdate(fetchLimit, 0, search, location, navigate);
    updatePaginationAndGetMessages(fetchLimit, 0, search);
  };

  const onDelete = async () => {
    const { skip } = qs.parse(location.search.replace(/^\?/, ''));
    setDeleting(true);
    try {
      const records = _.map(brandsToDelete, (brand: Brand) => ({
        id: brand.id,
        isDeleted: true,
      }));
      await BrandAPI.bulkUpdate(records);
      await getBrands(
        fetchLimit,
        _.toNumber(skip),
        searchStr,
        setBrandsCount,
        setBrands,
      );
      setStatusMessage({
        header: 'Brand Deletion Successful!',
        body: `Deleted ${_.size(brandsToDelete)} ${brandPluralizer(
          _.size(brandsToDelete),
        )}`,
        isFailure: false,
      });
      reset(INITIAL_BRANDS_TO_DELETE_FORM_VALUES);
    } catch (error) {
      setStatusMessage({
        header: 'Brand Deletion failed!',
        body: 'There was a problem in the deletion process.',
        isFailure: true,
      });
    }
    setDeleting(false);
    setDeleteModalOpen(false);
  };

  const onDismiss = () => {
    // clear the location state and status message
    navigate('.', { replace: true });
    setStatusMessage(null);
  };

  const animatedMessage = statusMessage && (
    <Message
      style={{ marginBottom: '20px' }}
      size="small"
      success={!statusMessage.isFailure}
      error={statusMessage.isFailure}
      icon={statusMessage.isFailure ? 'remove' : 'check'}
      header={statusMessage.header}
      content={statusMessage.body}
      onDismiss={onDismiss}
    />
  );

  const handleRowsSelect = () => {
    const selectedBrands: Array<Brand> = gridRef.current!.api.getSelectedRows();

    const newBrandsToDelete = {};
    selectedBrands.forEach((brand: Brand) => {
      newBrandsToDelete[brand.id] = brand;
    });
    setValue('brandsToDelete', newBrandsToDelete);
  };

  return (
    <WppPermissionPageTemplate
      user={user}
      permissions={Permission.manageBrands}
      unauthorizedComponent={
        <PageForbidden message="You are not permitted to view or manage brands." />
      }
      name={PAGE_NAME}
      customHeader={<BrandsPageHeader isAdminOrQAGlobal={isAdminOrQAGlobal} />}
    >
      <DataLoading loading={loading} pageName={PAGE_NAME}>
        <WppGrid container fullWidth>
          <WppGrid item all={24}>
            <Transition.Group animation="fade" duration={500}>{animatedMessage}</Transition.Group>
            <AgGridHeader
              handleDeleteRowAction={() => setDeleteModalOpen(!deleteModalOpen)}
              onSearchChange={(event) => onSearchChange((event.target as HTMLWppInputElement).value)}
              searchPlaceholder="Search by brand name"
              searchValue={searchStr}
              withSearch
              withDeletePanel={showDeletePanel}
            />
          </WppGrid>
        </WppGrid>
        <WppGrid container fullWidth>
          <WppGrid item all={24}>
            <AgGrid
              ref={gridRef}
              rowSelection="multiple"
              onRowSelected={handleRowsSelect}
              columnDefs={BrandsTableHeader(
                isAdminOrQAGlobal,
                isAdminUser,
                canEditBrand,
              )}
              rowMultiSelectWithClick
              rowData={brands}
              loading={loading}
              suppressRowClickSelection
            />
          </WppGrid>
          <WppGrid item all={24}>
            <WppPagination
              count={brandsCount}
              itemsPerPage={BRANDS_PER_PAGE_OPTIONS}
              activePageNumber={startingPage}
              selectedItemPerPage={fetchLimit}
              onWppChange={(event: Event) => {
                const { itemsPerPage, page } = (event as CustomEvent<
                PaginationChangeEventDetail
                >).detail;
                onDataRangeChange({ limit: itemsPerPage, start: (page - 1) * itemsPerPage });
              }}
            />
            {isAdminOrQAGlobal && (
              <WppDeleteConfirmationModal
                itemsToDelete={brandsToDelete}
                onCancel={() => setDeleteModalOpen(false)}
                open={deleteModalOpen}
                onDelete={onDelete}
                deleteItemName="brands"
                deleting={deleting}
                itemName="name"
                modalName="Delete Brands"
                disabled={!isAdminUser}
              />
            )}
          </WppGrid>
        </WppGrid>
      </DataLoading>
    </WppPermissionPageTemplate>
  );
};

export default Brands;
