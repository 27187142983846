import _ from 'lodash';
import React, { Dispatch } from 'react';
import { Button } from 'buildingBlocks';
import { SelectedCategoryFilter } from 'components/Notification/constants';
import { switcherStyles } from 'containers/NotificationCenter/style';
import NotificationsFilterButton from './NotificationsFilterButton';

type CategorySwitcherProps = {
  selectedCategoryFilter: SelectedCategoryFilter
  updateSelectedCategoryFilter: Dispatch<SelectedCategoryFilter>
  isLoading: boolean
};

const CategorySwitcher = ({ selectedCategoryFilter, updateSelectedCategoryFilter, isLoading }: CategorySwitcherProps) => (
  <div style={switcherStyles.switcherContainer}>
    <Button.Group style={_.omit(switcherStyles.switcherContainer, 'padding')}>
      <NotificationsFilterButton
        filterName={SelectedCategoryFilter.actions}
        selectedFilter={selectedCategoryFilter}
        setSelectedFilter={updateSelectedCategoryFilter}
        isLoading={isLoading}
        baseStyle={switcherStyles.switcherButton}
        activeStyle={switcherStyles.switcherActive}
      />
      <NotificationsFilterButton
        filterName={SelectedCategoryFilter.updates}
        selectedFilter={selectedCategoryFilter}
        setSelectedFilter={updateSelectedCategoryFilter}
        isLoading={isLoading}
        baseStyle={switcherStyles.switcherButton}
        activeStyle={switcherStyles.switcherActive}
      />
    </Button.Group>
  </div>
);

export default CategorySwitcher;
