import React, { Dispatch } from 'react';
import { myAllFilterStyles } from 'containers/NotificationCenter/style';
import { ViewFilters } from 'containers/NotificationCenter/types';
import NotificationsFilterButton from './NotificationsFilterButton';

type NotificationToggleViewProps = {
  viewFilter: ViewFilters
  updateViewFilter: Dispatch<ViewFilters>
  isLoading: boolean
};

const NotificationToggleView = ({ viewFilter, updateViewFilter, isLoading }: NotificationToggleViewProps) => (
  <div style={myAllFilterStyles.filterContainer}>
    <NotificationsFilterButton
      filterName={ViewFilters.my}
      selectedFilter={viewFilter}
      setSelectedFilter={updateViewFilter}
      isLoading={isLoading}
      baseStyle={myAllFilterStyles.filterBase}
      activeStyle={myAllFilterStyles.filterActive}
    />
    <NotificationsFilterButton
      filterName={ViewFilters.all}
      selectedFilter={viewFilter}
      setSelectedFilter={updateViewFilter}
      isLoading={isLoading}
      baseStyle={myAllFilterStyles.filterBase}
      activeStyle={myAllFilterStyles.filterActive}
    />
  </div>
);

export default NotificationToggleView;
