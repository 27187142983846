import React from 'react';
import _ from 'lodash';
import { Moment } from 'moment';
import env from 'env';
import { generateUUID } from 'utils/formattingUtils';
import { dateTimeFormatter } from 'utils/dateTime';
import QueryStringLink from 'components/QueryStringLink';
import { Table } from 'buildingBlocks';
import { DAG_STATES } from '../constants';
import HealthTableHeaders from './HealthTableHeaders';
import { DagRun } from '../types';

const HEADERS = ['Dag ID', 'Success', 'Failed', 'Running', 'Shutdown'];

type Props = {
  dagRuns: Array<DagRun>,
  startDate: Moment,
  endDate: Moment,
};

const DagRunsHealthTable = ({ dagRuns, startDate, endDate }: Props) => (
  // @ts-ignore semantic
  <Table style={{ marginTop: 0 }}>
    <HealthTableHeaders headers={HEADERS} />
    <Table.Body>
      {
        _.map(dagRuns, ((dag) => (
          <Table.Row key={generateUUID()}>
            <Table.Cell>
              <QueryStringLink
                to={`${env.AIRFLOW_URL}/dagrun/list`}
                params={{
                  _flt_3_state: dag.state,
                  _flt_3_dag_id: dag.dag_id,
                  _flt_1_execution_date: dateTimeFormatter.isoDateTime(startDate),
                  _flt_2_execution_date: dateTimeFormatter.isoDateTime(endDate),
                  sort: 2,
                }}
              >
                {dag.dag_id}
              </QueryStringLink>
            </Table.Cell>
            {
              _.map(DAG_STATES, (key) => (
                <Table.Cell key={generateUUID()}>
                  {
                    _.get(dag.state, key)
                      ? (
                        <QueryStringLink
                          to={`${env.AIRFLOW_URL}/dagrun/list`}
                          params={{
                            _flt_3_state: key,
                            _flt_3_dag_id: dag.dag_id,
                            _flt_1_execution_date: dateTimeFormatter.isoDateTime(startDate),
                            _flt_2_execution_date: dateTimeFormatter.isoDateTime(endDate),
                            sort: 2,
                          }}
                        >
                          {_.get(dag.state, key)}
                        </QueryStringLink>
                      )
                      : '-'
                  }
                </Table.Cell>
              ))
            }
          </Table.Row>
        )))
      }
    </Table.Body>
  </Table>
);

export default DagRunsHealthTable;
