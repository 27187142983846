import _ from 'lodash';
import React, { Dispatch } from 'react';
import { Checkbox, Grid, ObjectMultiDropdown } from 'buildingBlocks';
import { selectBrands, toggleUnread, updateFilter } from 'containers/NotificationCenter/actions';
import { Action, ActionTypes, State as FilterState, ViewFilters } from 'containers/NotificationCenter/types';
import { filters } from 'containers/NotificationCenter/style';
import { Brand } from 'utils/types';
import CategorySwitcher from './CategorySwitcher';
import NotificationToggleView from './NotificationToggleView';
import DatePicker from './DatePicker';

const { row, category, datepicker, brandsDropdown, unread, view } = filters;

const BRAND_SELECTION_LIMIT = 5;

type Option = {
  text: string
  value: string
};

type NotificationsFiltersProps = {
  dateRange: string
  isLoading: boolean
  dispatch: Dispatch<Action>
  filterState: FilterState
  brandCandidates: Array<Brand>
  loadingBrands: boolean
  brandError: string
};

const NotificationsFilters = ({
  dateRange,
  isLoading,
  dispatch,
  filterState,
  brandCandidates,
  loadingBrands,
  brandError,
}: NotificationsFiltersProps) => {
  const { selectedBrands, selectedCategoryFilter, viewFilter, unreadOnly } = filterState;
  const disableUnread = _.isEqual(viewFilter, ViewFilters.all) || isLoading;

  const handleSearchChange = (options: Array<Option>, searchQuery: string) => {
    const search = _.toLower(searchQuery);
    return _.filter(options, (option: Option) => _.includes(_.lowerCase(option.text), search));
  };

  const handleViewChange = (filterName: string) => {
    // uncheck unreadOnly filter when viewFilter is all
    if (_.isEqual(filterName, ViewFilters.all)) {
      dispatch(toggleUnread(true));
    }
    dispatch(updateFilter(ActionTypes.SELECT_VIEW_FILTER, filterName));
  };

  return (
    <Grid style={{ width: '100%', margin: 0 }}>
      <Grid.Row style={row}>
        <Grid.Column style={category}>
          <CategorySwitcher
            selectedCategoryFilter={selectedCategoryFilter}
            updateSelectedCategoryFilter={(filterName) => dispatch(updateFilter(ActionTypes.SELECT_CATEGORY_FILTER, filterName))}
            isLoading={isLoading}
          />
        </Grid.Column>
        <Grid.Column style={datepicker}>
          <DatePicker
            dateRange={dateRange}
            dispatch={dispatch}
            isLoading={isLoading}
          />
        </Grid.Column>
        <Grid.Column style={brandsDropdown}>
          <ObjectMultiDropdown
            fluid
            selection
            name="brands"
            placeholder="All Brands"
            loading={loadingBrands}
            value={selectedBrands}
            input={selectedBrands}
            options={brandCandidates}
            keyFn={(brand) => brand.name}
            selectionLimit={BRAND_SELECTION_LIMIT}
            onChange={(brands) => dispatch(selectBrands(brands))}
            disabled={loadingBrands || !!brandError || isLoading}
            renderLabel={(label) => ({ content: label.text })}
            search={{
              searchType: 'local',
              onSearchChange: handleSearchChange,
            }}
            customstyle={{ width: '100%' }}
          />
        </Grid.Column>
        <Grid.Column style={unread}>
          <Checkbox
            label="Unread Only"
            checked={unreadOnly}
            onClick={() => !disableUnread && dispatch(toggleUnread(unreadOnly))}
            disabled={disableUnread}
          />
        </Grid.Column>
        <Grid.Column style={{ width: '10.5%' }} />
        <Grid.Column style={view}>
          <NotificationToggleView
            viewFilter={viewFilter}
            updateViewFilter={handleViewChange}
            isLoading={isLoading}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default NotificationsFilters;
