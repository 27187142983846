import React from 'react';
import _ from 'lodash';
import { Moment } from 'moment';
import { generateUUID } from 'utils/formattingUtils';
import { Table } from 'buildingBlocks';
import QueryStringLink from 'components/QueryStringLink';
import { JOB_STATUSES } from '../constants';
import HealthTableHeaders from './HealthTableHeaders';
import { Job } from '../types';
import { getIsoDateTime } from '../utils';

const HEADERS = ['Type', 'Success', 'Failed', 'Running', 'New', 'Warnings'];

type Props = {
  jobs: Array<Job>,
  startDate: Moment,
  endDate: Moment,
};

const JobsHealthTable = ({ jobs, startDate, endDate }: Props) => (
  <Table style={{ marginTop: 0 }}>
    <HealthTableHeaders headers={HEADERS} />
    <Table.Body>
      {_.map(jobs, (job) => (
        <Table.Row key={job.type}>
          <Table.Cell>
            <QueryStringLink
              to="/jobs"
              params={{
                type: [job.type],
                startDate: getIsoDateTime(startDate),
                endDate: getIsoDateTime(endDate),
              }}
            >
              {job.type}
            </QueryStringLink>
          </Table.Cell>
          {_.map(JOB_STATUSES, (key) => (
            <Table.Cell key={generateUUID()}>
              {_.get(job.status, key)
                ? (
                  <QueryStringLink
                    to="/jobs"
                    params={{
                      type: [job.type],
                      startDate: getIsoDateTime(startDate),
                      endDate: getIsoDateTime(endDate),
                      status: [key],
                    }}
                  >
                    {_.get(job.status, key)}
                  </QueryStringLink>
                ) : '-'}
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

export default JobsHealthTable;
