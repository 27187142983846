import { CSSProperties } from 'react';
import { COPILOT_COLORS, COPILOT_LAYOUT, COPILOT_TYPOGRAPHY } from 'globalStyles';

const {
  NEW_DESIGN_SYSTEM: {
    BLUES,
    NEUTRALS,
    YELLOWS,
    TEALS,
  },
} = COPILOT_COLORS;
const { HEADING, BODY, DESCRIPTION } = COPILOT_TYPOGRAPHY;
const { SPACING } = COPILOT_LAYOUT;

type CSSStyleObject = { [key: string]: CSSProperties };

export const filters: CSSStyleObject = {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  category: {
    width: '20%',
    padding: 0,
  },
  datepicker: {
    width: '22%',
    maxWidth: '222px',
    padding: 0,
  },
  brandsDropdown: {
    width: '17.5%',
    maxWidth: '212px',
    padding: 0,
  },
  unread: {
    width: '12.5%',
    marginRight: SPACING[12],
    padding: 0,
  },
  view: {
    width: '12.5%',
    paddingTop: SPACING[4],
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

export const switcherStyles: CSSStyleObject = {
  switcherContainer: {
    backgroundColor: NEUTRALS.N50_ALABASTER,
    padding: SPACING[4],
    borderRadius: SPACING[40],
    width: '100%',
  },
  switcherActive: {
    backgroundColor: BLUES.B500_WAVE,
    color: NEUTRALS.N0_WHITE,
  },
  switcherButton: {
    borderRadius: SPACING[32],
    textTransform: 'initial',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    fontWeight: 500,
    width: '50%',
  },
};

const filterBaseDimensions = {
  height: SPACING[32],
  width: SPACING[60],
};

export const myAllFilterStyles: CSSStyleObject = {
  filterContainer: {
    display: 'flex',
    width: '120px',
    borderRadius: SPACING[40],
  },
  filterActive: {
    ...filterBaseDimensions,
    background: BLUES.B500_WAVE,
    color: NEUTRALS.N0_WHITE,
    margin: 0,
  },
  filterBase: {
    ...filterBaseDimensions,
    background: NEUTRALS.N0_WHITE,
    color: NEUTRALS.N600_ABBEY,
    margin: 0,
  },
};

export const datePickerStyles: CSSStyleObject = {
  calendarWrapper: {
    width: '100%',
    maxHeight: SPACING[36],
    margin: 0,
  },
  calendarIconStyle: {
    cursor: 'pointer',
    backgroundColor: 'white',
    border: 0,
    boxShadow: '0px 0px 0px',
    padding: SPACING[8],
  },
  colorSetting: {
    color: NEUTRALS.N800_MATTER,
    backgroundColor: NEUTRALS.N0_WHITE,
  },
  customInputContainer: {
    fontWeight: 400,
    paddingLeft: SPACING[8],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px',
    borderStyle: 'solid',
    borderColor: NEUTRALS.N200_LINEN,
    width: '100%',
    height: SPACING[36],
  },
};

export const sortableHeaderStyles: CSSStyleObject = {
  sortContainer: {
    ...HEADING.H6,
    width: '15%',
    cursor: 'pointer',
  },
  sortButton: {
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    position: 'relative',
    padding: 'none',
  },
  sortIcon: {
    color: NEUTRALS.N300_ALTO,
    fontSize: 16,
    position: 'absolute',
    left: SPACING[12],
  },
};

export const tableStyles: CSSStyleObject = {
  tableCustomStyle: {
    width: '100%',
    borderSpacing: 'none !important',
    borderRadius: SPACING[4],
  },
};
export const subjectStyles: CSSStyleObject = {
  subjectStyle: {
    ...HEADING.H6,
    width: '90%',
  },
  countStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    backgroundColor: YELLOWS.Y50_DAWN,
    color: YELLOWS.Y700_METAL,
    borderRadius: SPACING[20],
  },
  subjectContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export const messageStyles: CSSStyleObject = {
  tableCell: {
    width: '29%',
    gap: SPACING[4],
    maxWidth: '350px',
    wordBreak: 'break-word',
  },
  message: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 10,
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
  },
  dupMsg: {
    ...DESCRIPTION.BOLD,
    display: 'flex',
    color: YELLOWS.Y700_METAL,
    gap: SPACING[4],
    marginTop: SPACING[4],
  },
  triangleIcon: {
    height: SPACING[20],
    width: SPACING[12],
  },
};

export const actionsImageBase = {
  ...BODY.SMALL,
  margin: 0,
  padding: SPACING[8],
  cursor: 'pointer',
};

export const actionsStyles: CSSStyleObject = {
  tableCell: {
    width: '95%',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  popUp: {
    padding: 0,
  },
  popUpContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '160px',
    height: SPACING[40],
  },
  popUpImage: {
    ...actionsImageBase,
    justifyContent: 'center',
    display: 'flex',
    gap: '6px',
  },
  takeAction: {
    width: '90%',
  },
  takeActionText: {
    paddingRight: SPACING[4],
    margin: 0,
  },
  anchorText: {
    color: BLUES.B500_WAVE,
    display: 'flex',
    cursor: 'pointer',
  },
  diagonalArrowIcon: {
    transform: 'rotate(45deg)',
  },
  ellipsis: {
    marginBottom: SPACING[8],
    cursor: 'pointer',
  },
};

export const noNotificationsStyle = {
  ...BODY.LARGE,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '240px',
  color: NEUTRALS.N400_GRANITE,
};

export const stratInfoStyles: CSSStyleObject = {
  container: {
    ...BODY.SMALL,
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[4],
    wordBreak: 'break-all',
  },
  colorMatter: {
    color: NEUTRALS.N800_MATTER,
  },
  colorSherpa: {
    color: BLUES.B900_SHERPA,
  },
  memAdvSeparate: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[4],
  },
  memAdvOneLine: {
    display: 'flex',
    gap: SPACING[8],
  },
  adv: {
    color: TEALS.T700_LOCHINVAR,
  },
  mem: {
    color: NEUTRALS.N400_GRANITE,
  },
};
