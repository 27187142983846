import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { Table } from 'buildingBlocks';
import { NotificationMsgObject } from 'components/Notification/types';
import { State as FilterState } from 'containers/NotificationCenter/types';
import { User } from 'utils/types';
import NotificationRow from './NotificationRow';

type NotificationTableBodyProps = {
  notifications: Array<NotificationMsgObject>
  filterState: FilterState
};

const NotificationTableBody = ({ notifications, filterState }: NotificationTableBodyProps) => {
  const { id: userId } = useSelector<GlobalState>((state) => state.login.user) as User;

  return (
    <Table.Body>
      {_.map(notifications, (notification) => (
        <NotificationRow
          key={notification.id}
          notification={notification}
          filterState={filterState}
          userId={userId}
        />
      ))}
    </Table.Body>
  );
};

export default NotificationTableBody;
